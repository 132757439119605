import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SkillCard from "./SkillCard.js"

import javascript from "../assets/skills/javascript.svg"
import python from "../assets/skills/python.svg"
import reactIcon from "../assets/skills/react.svg"
import windows from "../assets/skills/windows.svg"
import git from "../assets/skills/git.svg"
import express from "../assets/skills/express.svg"
import unity from "../assets/unitylogo.png"
import unreal from "../assets/unreallogo.png"
import hr from "../assets/curve-hr.svg"
import nodejs from "../assets/skills/nodejs.svg"

export default function Skills() {
    const settings = {
        dots: false,
        autoplay: true,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
      };

    return (
        <div id="skills" className="mt-4 text-white">
            <h1 className="text-2xl font-bold">Skills</h1>
            <p className="font-light text-gray-400">Here are some of my skills</p>

            <div className="mt-4">
                <Slider {...settings}>
                <SkillCard name="React" experience="2 years" img={reactIcon} />
                <SkillCard name="Unity" experience="3 years" img={unity} />
                <SkillCard name="Node.js" experience="1 year" img={nodejs} />
                <SkillCard name="Python" experience="2 years" img={python} />
                <SkillCard name="JavaScript" experience="3 years" img={javascript} />
                <SkillCard name="Windows" experience="10+ years" img={windows} />
                <SkillCard name="Unreal Engine" experience="1 year" img={unreal} />
                <SkillCard name="git" experience="3 years" img={git} />
                <SkillCard name="Express" experience="1 year" img={express} />
                </Slider>
            </div>
            <img src={hr} className="w-full mt-8 md:h-3" alt="hr" />
        </div>
    )
}