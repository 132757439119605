import ProjectCard from "./ProjectCard.js"

import hr from "../assets/curve-hr.svg"
import shift from "../assets/shift1.PNG"
import gs from "../assets/greenhouse-seattle.PNG"
import gcs from "../assets/gcs.png"


export default function Projects(){
    return (
        <div id="certs" className="mt-4 text-white">
            <h1 className="text-2xl font-bold">Projects</h1>
            <p className="font-light text-gray-400">Here are some of my projects</p>

            <div className="grid grid-cols-1 md:grid-cols-3 justify-center mt-4 gap-5">
                <ProjectCard
                    name="Shift"
                    img={shift}
                    detail="Unity"
                    date="Aug 2023"
                    overlayText="Shift is a 2D side-scroller puzzle platformer game I am currently making in Unity." />
                <ProjectCard
                    name="Greenhouse Seattle"
                    img={gs} detail="Squarespace"
                    date="Jun 2022"
                    url="https://greenhouse-seattle.com/"
                    overlayText="I set up Greenhouse Seattle's online marketplace and subscription delivery service. From this they saw a 60% net increase of online sales and subscriptions."
                />
                <ProjectCard
                    name="Ghost Crow Studio"
                    img={gcs} detail="Ghost Crow Studio"
                    date="Jun 2022"
                    overlayText="I created Ghost Crow Studio's flagship website and greatly boosed their online presence and helped facilitate sales."
                />

            </div>
            <img src={hr} className="w-full mt-8 md:h-2" alt="hr" />
        </div>
    )
}
