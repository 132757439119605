import {useState} from "react";

export default function ProjectCard(props) {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <div className="relative">
            <a href={props.url} target="_blank" rel="noreferrer">
                <div data-aos="fade-up" data-aos-duration="500" data-aos-offset="100"
                     className="hover:bg-dark w-full h-full bg-dark-200 rounded-md py-4 px-4"
                     onMouseEnter={() => setIsHovered(true)}
                     onMouseLeave={() => setIsHovered(false)}
                >
                    <img src={props.img} className="w-full h-56 mx-auto object-cover" alt={props.name}></img>
                    <div className="mt-2">
                        <h1 className="font-bold md:text-xl">{props.name}</h1>
                        <p className="font-light md:text-lg">{props.detail}</p>
                        <p className="font-light text-gray-400">{props.desc}</p>

                        <p className="font-light text-gray-400">{props.date}</p>
                    </div>
                    {
                        isHovered && (
                            <div className="overlay absolute inset-0 flex items-center justify-center bg-black bg-opacity-80 text-white opacity-100 transition-opacity duration-300 text-center text-xl p-4">
                                {props.overlayText}
                            </div>
                        )
                    }
                </div>
            </a>
        </div>
    )
}
